import React, {Component} from 'react';
import { Modal, ModalVariant, Button,
    Form,
    FormGroup,
    FormHelperText,
    HelperText,
    Popover,
    HelperTextItem,
    TextInput } from '@patternfly/react-core';

import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import ExclamationTriangleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-triangle-icon';
import CheckCircleIcon from '@patternfly/react-icons/dist/esm/icons/check-circle-icon';

import client from '../../shared/feathers';

export default class SetPassword extends Component {
  state={
    loading: true,
    password: "",
    ruleLength: 'indeterminate',
    ruleContent: 'indeterminate',
    ruleCharacters: 'indeterminate',
    passStrength: { variant: 'error', icon: <ExclamationCircleIcon />, text: 'Weak' }
  }
  componentDidMount() {

  }
  
  handlePasswordInput (password) {
    this.setState({ password });
    this.validate(password);
  };

  validate (password) {
    if (password === '') {
      this.setState({
        ruleLength: 'indeterminate',
        ruleCharacters: 'indeterminate'
      });
      return;
    }

    if (password.length < 6) {
      this.setState({ ruleLength: 'error' });
    } else {
      this.setState({ ruleLength: 'success' });
    }

    let rulesCount = 0;
    let strCount = 0;
    if (/[a-z]/g.test(password)) {
      rulesCount++;
    }
    if (/[A-Z]/g.test(password)) {
      strCount += password.match(/[A-Z]/g).length;
      rulesCount++;
    }
    if (/\d/g.test(password)) {
      strCount += password.match(/\d/g).length;
      rulesCount++;
    }
    if (/\W/g.test(password)) {
      strCount += password.match(/\W/g).length;
      rulesCount++;
    }

    if (rulesCount < 3) {
      this.setState({ ruleCharacters: 'error' });
    } else {
      this.setState({ ruleCharacters: 'success' });
    }

    if (strCount < 3) {
      this.setState({ passStrength: { variant: 'error', icon: <ExclamationCircleIcon />, text: 'Weak' } });
    } else if (strCount < 5) {
      this.setState({ passStrength: { variant: 'warning', icon: <ExclamationTriangleIcon />, text: 'Medium' } });
    } else {
      this.setState({ passStrength: { variant: 'success', icon: <CheckCircleIcon />, text: 'Strong' } });
    }
  };

  async save(){
    await client.service("departments").patch(this.props.dep.id,{password: this.state.password})
    this.setState({password:""})
    this.props.close()
  }
  render(){
    
    const { password, ruleLength, ruleContent, ruleCharacters, passStrength } = this.state;
    let passStrLabel = (
        <HelperText>
          <HelperTextItem variant={passStrength.variant} icon={passStrength.icon}>
            {passStrength.text}
          </HelperTextItem>
        </HelperText>
    );
    return (
        <Modal
          variant={ModalVariant.small}
          title={"Passwort setzen für Filiale #"+this.props.dep.id}
          isOpen={this.props.isOpen}
          onClose={this.props.close}
          actions={[
            <Button key="confirm" isDisabled={(ruleLength === 'error' || ruleCharacters === 'error')||this.state.password==""} variant="primary" onClick={this.save.bind(this)}>
              Speichern
            </Button>,
            <Button key="cancel" variant="link" onClick={this.props.close}>
              Abbrechen
            </Button>
          ]}
        >
            <Form>
                <FormGroup
                label="Password"
                isRequired
                fieldId="password-field"
                {...(ruleLength === 'success' &&
                    ruleContent === 'success' &&
                    ruleCharacters === 'success' && {
                    labelInfo: passStrLabel
                    })}
                >
                <TextInput
                    isRequired
                    type="text"
                    id="password-field"
                    name="password-field"
                    aria-describedby="password-field-helper"
                    aria-invalid={ruleLength === 'error' || ruleCharacters === 'error'}
                    value={password}
                    onChange={this.handlePasswordInput.bind(this)}
                />
                <FormHelperText isHidden={false} component="div">
                    <HelperText component="ul" aria-live="polite" id="password-field-helper">
                    <HelperTextItem isDynamic variant={ruleLength} component="li">
                        Das Passwort muss mindestens 6 Zeichen lang sein.
                    </HelperTextItem>
                    <HelperTextItem isDynamic variant={ruleCharacters} component="li">
                        Muss mindestens 3 der folgenden Elemente enthalten: Kleinbuchstaben, Großbuchstaben, Zahlen, Symbole
                    </HelperTextItem>
                    </HelperText>
                </FormHelperText>
                </FormGroup>
            </Form>
        </Modal>
    );
  }
};