import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import reactive from 'feathers-reactive';

// import memory from 'feathers-memory';

// app.use('/register', memory());
let socket;
if (location.hostname.includes('dev')) {
  socket = io('https://lieferbericht-api.dev.h.kamphenkel.net');
} else {
  socket = io('https://lieferbericht-api.h.kamphenkel.net');
}
const client = feathers();

client.configure(feathers.socketio(socket, {
  timeout: 20000
}));
client.configure(reactive({ idField: 'id' }));
client.configure(feathers.authentication({
  storage: window.localStorage
}));
window.client = client;
export default client;

// api.socket.on('rate-limit', (error) => ...)
// api.socket.on('disconnect', () => ...)
