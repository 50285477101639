import React, {Component} from 'react';
import { Table, TableHeader, TableBody,EditableTextCell,applyCellEdits,cancelCellEdits,validateCellEdits } from '@patternfly/react-table';
import { PageSection,TextContent,Text,PageSectionVariants } from '@patternfly/react-core';
import client from '../../shared/feathers';
import SetPassword from './SetPassword';

export default class Departments extends Component {
  state={
    loading: true,
    data:[ ],
    setPassword:false,
    setPasswordData: {id:0},
    header: [
      {title:"ID"},
      {title:"Name"},
      {title:"Standort"},
      {title:"Koordinaten"},
      {title:" "},
      {title:"Admin"},
    ],
    actions: [
      {
        title: 'Passwort setzen',
        onClick: (event, rowId, rowData, extra) => this.setState({setPassword:true, setPasswordData:rowData.original})
      }
    ],
  }
  componentDidMount() {

    this.setState({loading:true})
    if(this.reactive){this.reactive.unsubscribe()}
    this.reactive = client.service("departments").watch().find({query:{$sort:{id:1}}}).subscribe(async ({data})=>{
      // console.log(data)
      let Formatted = []
      for (let row of data){
        Formatted.push({
          original:row,
          cells:[
            {
              title: row.id,
              props: {  }
            },
            {
              title: (value, rowIndex, cellIndex, props) => (
                <EditableTextCell
                  value={value} rowIndex={rowIndex} cellIndex={cellIndex} props={props}
                  handleTextInputChange={this.handleTextInputChange.bind(this)}
                />
              ),
              props: { value: row.name, name: row.id+"_name" }
            },
            {
              title: (value, rowIndex, cellIndex, props) => (
                <EditableTextCell
                  value={value} rowIndex={rowIndex} cellIndex={cellIndex} props={props}
                  handleTextInputChange={this.handleTextInputChange.bind(this)}
                />
              ),
              props: { value: row.location, name: row.id+"_location" }
            },
            {
              title: (value, rowIndex, cellIndex, props) => (
                <EditableTextCell
                  value={value} rowIndex={rowIndex} cellIndex={cellIndex} props={props}
                  handleTextInputChange={this.handleTextInputChange.bind(this)}
                />
              ),
              props: { value: row.lat, name: row.id+"_lat" }
            },
            {
              title: (value, rowIndex, cellIndex, props) => (
                <EditableTextCell
                  value={value} rowIndex={rowIndex} cellIndex={cellIndex} props={props}
                  handleTextInputChange={this.handleTextInputChange.bind(this)}
                />
              ),
              props: { value: row.long, name: row.id+"_long" }
            },
            { title: row.is_admin, props:{}}
          ],
        })
      }
      this.setState({data:Formatted,loading:false})
    })
  }

  componentWillUnmount(){
    if(this.reactive){this.reactive.unsubscribe()}
  }
  updateEditableRows  (evt, type, isEditable, rowIndex, validationErrors) {
    
    let newRows = Array.from(this.state.data);
    //console.log(type,rowIndex,newRows[rowIndex])

    if (validationErrors && Object.keys(validationErrors).length) {
      newRows[rowIndex] = validateCellEdits(newRows[rowIndex], type, validationErrors);
      this.setState({ data: newRows });
      return;
    }

    if (type === 'cancel') {
      newRows[rowIndex] = cancelCellEdits(newRows[rowIndex]);
      this.setState({ data: newRows });
      return;
    }

    newRows[rowIndex] = applyCellEdits(newRows[rowIndex], type);

    if(type === 'save') {
      console.log(newRows[rowIndex])
      client.service("departments").patch(newRows[rowIndex].original.id,{
        name:newRows[rowIndex].cells[1].props.value,
        location:newRows[rowIndex].cells[2].props.value,
        lat:newRows[rowIndex].cells[3].props.value,
        long:newRows[rowIndex].cells[4].props.value,
      } ).then(()=>{})
    }

    this.setState({ data: newRows });
  };

  handleTextInputChange (newValue, evt, rowIndex, cellIndex)  {
    // console.log(newValue);
    let newRows = Array.from(this.state.data);
    newRows[rowIndex].cells[cellIndex].props.editableValue = newValue;
    this.setState({
      rows: newRows
    });
  };
  render(){
    
    return (
      <React.Fragment>
        <SetPassword close={()=>this.setState({setPassword:false})} isOpen={this.state.setPassword} dep={this.state.setPasswordData} />
        <PageSection variant={PageSectionVariants.light}>
          <TextContent>
            <Text component="h1">Filialen</Text>
            <Text component="p">
              Hier können Sie Ihre Filialen verwalten und deren Anmelde-Passwort definieren
            </Text>
          </TextContent>
        </PageSection>
        <PageSection>
          <Table 
          onRowEdit={this.updateEditableRows.bind(this)}
          cells={this.state.header} 
          aria-label="Editable Rows Table"
          rows={this.state.data}
          variant="compact"
          actions={this.state.actions}
          >
            <TableHeader />
            <TableBody />
          </Table>
        </PageSection>
      </React.Fragment>
    );
  }
};