import React, { Component } from 'react';
import {
  Modal, ModalVariant, Button,
  Form, FormGroup,
  TextInput, FormSelect, FormSelectOption, Checkbox,
  ActionGroup, Switch,
  Nav, NavList, NavItem
} from '@patternfly/react-core';

import client from '../../shared/feathers';

export default class SetPassword extends Component {
  constructor (props) {
    super(props);
    this.state = { loading: true, password: '', col: 1, ...props.list };
  }

  async save () {
    const data = {};
    for (const col in Array.apply(0, Array(15))) {
      data['col_' + (Number(col) + 1) + '_name'] = this.state['col_' + (Number(col) + 1) + '_name'];
      data['col_' + (Number(col) + 1) + '_width'] = this.state['col_' + (Number(col) + 1) + '_width'];
      data['col_' + (Number(col) + 1) + '_field'] = this.state['col_' + (Number(col) + 1) + '_field'];
      data['col_' + (Number(col) + 1) + '_mode'] = this.state['col_' + (Number(col) + 1) + '_mode'];
    }
    await client.service('order_list_configurations').patch(this.props.list.id, { ...data });
    this.props.close();
  }

  render () {
    const { col } = this.state;
    return (
      <Modal
        variant={ModalVariant.small}
        title={'Liste bearbeiten (ID:' + this.props.list.id + ')'}
        isOpen={this.props.isOpen}
        onClose={this.props.close}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        actions={[
          <Button key='confirm' variant='primary' onClick={this.save.bind(this)}>
            Speichern
          </Button>,
          <Button key='cancel' variant='link' onClick={this.props.close}>
            Abbrechen
          </Button>
        ]}
      >
        <Nav onSelect={this.onSelect} variant='tertiary' style={{ marginBottom: 10, borderTop: '1px solid #f0f0f0', borderBottom: '1px solid #f0f0f0' }}>
          <NavList>
            {Array.apply(0, Array(15)).map((x, num) => (
              <NavItem onClick={() => this.setState({ col: num + 1 })} key={num + 1} itemId={num + 1} isActive={col == num + 1} href='#'>
                {this.state['col_' + (num + 1) + '_name'] ? this.state['col_' + (num + 1) + '_name'] : 'Spalte ' + (num + 1)}
              </NavItem>
            ))}
          </NavList>
        </Nav>
        <div style={{ padding: 24 }}>
          <Switch
            id='simple-switch'
            onChange={val => this.setState({ ['col_' + col + '_name']: val ? '' : null })}
            label='Aktiviert' labelOff='Deaktiviert'
            isChecked={this.state['col_' + col + '_name'] !== null}
          />
        </div>
        {(this.state['col_' + col + '_name'] !== null) && <div style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Form isHorizontal>
            <FormGroup label='Name' fieldId='name-field'>
              <TextInput
                key={col + '_name'}
                defaultValue={this.state['col_' + col + '_name']}
                onChange={(val) => this.setState({ ['col_' + col + '_name']: val })}
                type='text' id='name-field' name='name-field'
              />
            </FormGroup>
            <FormGroup label='Modus' fieldId='mode-field'>
              <FormSelect
                value={this.state['col_' + col + '_mode']}
                onChange={(val) => this.setState({ ['col_' + col + '_mode']: val })}
                id='horzontal-form-title' name='horizontal-form-title'
              >
                <FormSelectOption key='0' value='0' label='0 - Eingabe' />
                <FormSelectOption key='1' value='1' label='1 - Betrag' />
                <FormSelectOption key='3' value='3' label='3 - Anzeigen' />
                <FormSelectOption key='4' value='4' label='4 - Packungsweise' />
                <FormSelectOption key='5' value='5' label='5 - Preis' />
                <FormSelectOption key='6' value='6' label='6 - Uhrzeit' />
              </FormSelect>
            </FormGroup>
            <FormGroup label='Spalte' fieldId='field-field'>
              <TextInput
                type='number' key={col + '_field'}
                defaultValue={this.state['col_' + col + '_field']}
                onChange={(val) => this.setState({ ['col_' + col + '_field']: val })}
                id='field-field' name='field-field'
              />
            </FormGroup>
            <FormGroup label='Breite' fieldId='width-field'>
              <TextInput
                type='number' key={col + '_width'}
                defaultValue={this.state['col_' + col + '_width']}
                onChange={(val) => this.setState({ ['col_' + col + '_width']: val })}
                id='field-field' name='_width-field'
              />
            </FormGroup>
          </Form>
        </div>}
      </Modal>
    );
  }
}
