
import React from 'react';
import {
  Nav, NavItem, NavGroup,
  PageSidebar,
  PageSection
} from '@patternfly/react-core';
import { withRouter, useHistory } from 'react-router-dom';

class Menu extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      activeItem: '/departments'
    };
    this.onSelect = result => {
      this.props.history.push(result.itemId);
      this.setState({
        activeItem: result.itemId
      });
    };
  }

  render () {
    const { activeItem } = this.state;
    const { isNavOpen } = this.props;
    return (
      <Nav onSelect={this.onSelect.bind(this)}>
        <NavGroup title='Verwaltung'>
          <NavItem preventDefault to='#grouped-1' itemId='/departments' isActive={activeItem === '/departments'}>
            Filialen
          </NavItem>
          <NavItem preventDefault to='#grouped-2' itemId='/lists' isActive={activeItem === '/lists'}>
            Listen
          </NavItem>
          {/* <NavItem preventDefault to="#grouped-3" itemId="/export" isActive={activeItem === '/export'}>
                        Export
                    </NavItem> */}
        </NavGroup>
      </Nav>
    );
  }
}

export default withRouter(Menu)
;
