import React from 'react';
import {
  Page,
  Masthead,
  MastheadToggle,
  MastheadMain,
  MastheadBrand,
  ToolbarGroup,
  MastheadContent,
  PageSidebar,
  PageSection,
  PageToggleButton,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  Button
} from '@patternfly/react-core';
import {BrowserRouter, Route } from 'react-router-dom'
import BarsIcon from '@patternfly/react-icons/dist/esm/icons/bars-icon';
import PowerOffIcon from '@patternfly/react-icons/dist/esm/icons/power-off-icon';
import client from '../shared/feathers'
import Departments from './Departments'
import Lists from './Lists'
import Menu from './Menu'

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: true
    };
    this.onNavToggle = () => {
      this.setState({
        isNavOpen: !this.state.isNavOpen
      });
    };
  }

  render() {
    const { isNavOpen } = this.state;

    const headerToolbar = (
      <Toolbar id="toolbar"  isFullHeight isStatic>
        <ToolbarContent alignment={{ default: 'alignRight' }}>
            <ToolbarGroup  alignment={{ default: 'alignRight' }}>
                <ToolbarItem>
                    <Button onClick={()=>client.logout()} variant="plain" aria-label="Action">
                        <PowerOffIcon />
                    </Button>
                </ToolbarItem>
            </ToolbarGroup>
        </ToolbarContent>
      </Toolbar>
    );

    const Header = (
      <Masthead>
        <MastheadToggle>
          <PageToggleButton
            variant="plain"
            aria-label="Global navigation"
          >
            <BarsIcon />
          </PageToggleButton>
        </MastheadToggle>
        <MastheadMain>
            <b>LIEFERBERICHT</b>
        </MastheadMain>
        <MastheadContent>{headerToolbar}</MastheadContent>
      </Masthead>
    );
    const Sidebar = <PageSidebar nav={<Menu />}  />
    return (
        <BrowserRouter>
          <Page header={Header} isManagedSidebar sidebar={Sidebar} style={{height: '100vh'}}>
              <Route path="/" exact component={()=><Departments />} />
              <Route path="/departments" exact component={()=><Departments />} />
              <Route path="/lists" exact component={()=><Lists />} />
              <Route path="/export" exact component={()=><Lists />} />
            <PageSection isFilled={true}>
            </PageSection>
          </Page>
        </BrowserRouter>
    );
  }
}