import React,{Component} from 'react'
import {render} from 'react-dom'
import {BrowserRouter as Router,withRouter} from 'react-router-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import client from './shared/feathers'
import './shared/extra.css'
import '@patternfly/patternfly/patternfly.css'

import Authentification from './Authentication';
import Dashboard from './Dashboard';

Sentry.init({
  dsn: "https://2a3d6e04eac54e13afbce52ce49daf19@sentry.tecoyo.com/6",
  environment: "admin_"+(location.hostname == "localhost"?"development":"production"),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});


class PreApp extends Component {
    state={
        login: null,
        id:"tedt"
    }
    
    componentDidMount(){
        const params = new URLSearchParams(this.props.location.search);
        if(params.get('noauth')){
            if(params.get('noauth')=="1"){
                return
            }
        }else{
            client.authenticate().catch(() => this.setState({ login: false }));
            client.on('authenticated', () => {this.setState({login:true})} )
            client.on('logout', () => {this.setState({login:false})} )
        }
    }
    render(){
        if(this.state.login == null){
            return <Authentification loading></Authentification>
        }else if(this.state.login == false){
            return <Authentification  />
        }
        return <Dashboard />
        
    }

}
let App = withRouter(PreApp)
render(<Router><App/></Router>, document.querySelector('#app'))


