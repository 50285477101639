import React from 'react';
import brandImg from '../shared/logo.svg';
import {
  LoginFooterItem,
  LoginForm,
  LoginMainFooterBandItem,
  LoginMainFooterLinksItem,
  LoginPage,
  BackgroundImageSrc,
  ListItem
} from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import client from '../shared/feathers';

export default class SimpleLoginPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showHelperText: false,
      usernameValue: '',
      isValidUsername: true,
      passwordValue: '',
      isValidPassword: true,
      isRememberMeChecked: false
    };

    this.handleUsernameChange = value => {
      this.setState({ usernameValue: value });
    };

    this.handlePasswordChange = passwordValue => {
      this.setState({ passwordValue });
    };

    this.onRememberMeClick = () => {
      this.setState({ isRememberMeChecked: !this.state.isRememberMeChecked });
    };

    this.onLoginButtonClick = event => {
      event.preventDefault();
      this.setState({ isValidUsername: !!this.state.usernameValue });
      this.setState({ isValidPassword: !!this.state.passwordValue });
      this.setState({ showHelperText: !this.state.usernameValue || !this.state.passwordValue });
      client.authenticate({
        strategy: 'local',
        id: this.state.usernameValue,
        password: this.state.passwordValue
      }).catch(err => { console.log(err); this.setState({ showHelperText: true }); });
    };
  }

  render () {
    const helperText = (
      <>
        &nbsp;Anmeldung Fehlgeschlagen.
      </>
    );

    const forgotCredentials = (
      <LoginMainFooterBandItem>
        Brauchst du Hilfe bei der Anmeldung? <a href='https://kamphenkel.net'>Kontaktiere uns!</a>
      </LoginMainFooterBandItem>
    );

    const listItem = (
      <>
        <ListItem>
          <LoginFooterItem href='https://kamphenkel.net'><i class='fas fa-at' /> Webseite</LoginFooterItem>
        </ListItem>
        <ListItem>
          <LoginFooterItem href='mailto:info@kamphenkel.net'><i class='fas fa-envelope' /> info@kamphenkel.net</LoginFooterItem>
        </ListItem>
        <ListItem>
          <LoginFooterItem href='tel:0049531303695'><i class='fas fa-phone' /> 0531 / 30 36 95</LoginFooterItem>
        </ListItem>
      </>
    );

    const loginForm = (
      <LoginForm
        showHelperText={this.state.showHelperText}
        helperText={helperText}
        helperTextIcon={<ExclamationCircleIcon />}
        usernameLabel='Filialnummer'
        usernameValue={this.state.usernameValue}
        onChangeUsername={this.handleUsernameChange}
        isValidUsername={this.state.isValidUsername}
        passwordLabel='Passwort'
        passwordValue={this.state.passwordValue}
        onChangePassword={this.handlePasswordChange}
        isValidPassword={this.state.isValidPassword}
        // rememberMeLabel="Keep me logged in for 30 days."
        isRememberMeChecked={this.state.isRememberMeChecked}
        onChangeRememberMe={this.onRememberMeClick}
        onLoginButtonClick={this.onLoginButtonClick}
        loginButtonLabel='Anmelden'
      />
    );

    const images = {
      lg: require('../shared/img/pfbg_1200.jpeg')
    };

    return (
      <LoginPage
        footerListVariants='inline'
        backgroundImgSrc={images}
        backgroundImgAlt='Images'
        // footerListItems={listItem}
        // textContent="This is placeholder text only. Use this area to place any information or introductory message about your application that may be relevant to users."
        loginTitle='Lieferberichte'
        loginSubtitle='Melden Sie sich an um die Lieferberichte anzufordern oder zu Konfigurieren'
        // socialMediaLoginContent={socialMediaLoginContent}
        // signUpForAccountMessage={signUpForAccountMessage}
        forgotCredentials={forgotCredentials}
      >
        {loginForm}
      </LoginPage>
    );
  }
}
