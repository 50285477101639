import React, {Component} from 'react';
import { Table, TableHeader, TableBody,EditableTextCell,applyCellEdits,cancelCellEdits,validateCellEdits } from '@patternfly/react-table';
import { Text,PageSection, TextContent,PageSectionVariants } from '@patternfly/react-core';
import client from '../../shared/feathers';
import Edit from './edit';

export default class Departments extends Component {
  state={
    loading: true,
    data:[ ],
    isEdit:false,
    selected:{id:0},
    header: [
      {title:"ID"},
      {title:"Status"},
      ""
    ],
    actions: [
      {
        title: 'Bearbeiten',
        onClick: (event, rowId, rowData, extra) => {this.setState({isEdit:true, selected:rowData.original}, this.update.bind(this));}
      },
      {
        title: 'Deaktivieren',
        onClick: (event, rowId, rowData, extra) => { client.service("order_list_configurations").patch(rowData.original.id,{disabled:true}).then(e=>""); }
      },
      {
        title: 'Aktivieren',
        onClick: (event, rowId, rowData, extra) => { client.service("order_list_configurations").patch(rowData.original.id,{disabled:false}).then(e=>""); }
      }
    ],
  }
  async update(){
    let {data} = await client.service("order_list_configurations").find({query:{$limit:1000000,$sort:{id:1}}})
      // console.log(data)
      let Formatted = []
      let idList = []
      for (let row of data){
        if(!idList.includes(row.id)){
            Formatted.push({
                original:row,
                cells:[
                    row.id,
                    row.disabled?"Deaktiviert":"Aktiviert",
                    [row.col_1_name,row.col_2_name,row.col_3_name,row.col_4_name,row.col_5_name].filter(e=>!!e).join(", ")
                ],
            })
            idList.push( row.id )
        }
      }
      this.setState({data:Formatted,loading:false})
  }
  componentDidMount() {

    this.setState({loading:true})
    if(this.reactive){this.reactive.unsubscribe()}
    this.reactive = client.service("order_list_configurations").watch().find({query:{$limit:1000000,$sort:{id:1}}}).subscribe(async ({data})=>{
      console.log(data)
      let Formatted = []
      let idList = []
      for (let row of data){
        if(!idList.includes(row.id)){
            Formatted.push({
                original:row,
                cells:[
                    row.id,
                    row.disabled?"Deaktiviert":"Aktiviert",
                    [row.col_1_name,row.col_2_name,row.col_3_name,row.col_4_name,row.col_5_name].filter(e=>!!e).join(", ")
                ],
            })
            idList.push( row.id )
        }
      }
      console.log(Formatted)

      this.setState({data:Formatted,loading:false})
    })
  }

  componentWillUnmount(){
    if(this.reactive){this.reactive.unsubscribe()}
  }

  render(){
    
    return (
      <React.Fragment>
        {this.state.isEdit && <Edit close={()=>this.setState({isEdit:false})} isOpen={true} list={this.state.selected} />}
        <PageSection variant={PageSectionVariants.light}>
          <TextContent>
            <Text component="h1">Listen</Text>
            <Text component="p">
              Bereite deine Eingabemasken für die Filialen vor.
            </Text>
          </TextContent>
        </PageSection>
        <PageSection>
          <Table 
          cells={this.state.header} 
          rows={this.state.data}
          aria-label="Order Lists"
          variant="compact"
          actions={this.state.actions}
          >
            <TableHeader />
            <TableBody />
          </Table>
        </PageSection>
      </React.Fragment>
    );
  }
};